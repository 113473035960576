<template>
  <Member
    title="Dr. Ugo Elmore"
    description="Chirurgia Generale e Chirurgia Gastroenterologica"
    text="Il campo di maggiore interesse clinico è rappresentato dalla chirurgia dell’apparato digerente, settore in cui applica nuove tecniche e tecnologie di chirurgia mininvasiva. In particolare, il dottore Elmore tratta con approccio mininvasivo, oltre ovviamente che con approccio tradizionale, le patologie esofagea e gastrica, epatobiliare, colorettale, sia per neoplasie, sia per malattie benigne o funzionali. Nell’ambito della chirurgia endocrina, tratta la patologia del surrene con accesso laparoscopico. Collabora con i colleghi ematologi per lo staging delle malattie emolinfoproliferative ed esegue la splenectomia con approccio laparoscopico. Ha sviluppato e messo a punto metodiche combinate di laparoscopia ed endoscopia per il trattamento delle lesioni intraviscerali del tratto digestivo. Inoltre, Ugo Elmore ha sviluppato anche l’attività di day surgery: in tale regime di ricovero esegue con accesso laparoscopico interventi di colecistectomia e di plastica della parete addominale, oltre che interventi per riparazione della parete addominale con accesso anteriore in anestesia locale. Ha esperienza nel trattamento della patologia proctologica utilizzando le diverse metodiche standard per la cura delle emorroidi e del prolasso rettale in regime di day surgery e one day surgery.L’attività scientifica e di ricerca del dottore Elmore si è sempre svolta nell’ambito delle patologie riguardanti le alte e basse vie digestive. Diversi sono i trial multicentrici a cui partecipa in qualità di Principal Investigator e Co- investigator in collaborazione con diversi centri nazionali ed internazionali."
    :formation="[
      'Laurea in Medicina e Chirurgia  – Università degli Studi “La Sapienza”, Roma',
      'Specializzazione in Chirurgia Generale – Università degli Studi “La Sapienza”, Roma',
      'Dottorato di Ricerca in Chirurgia Generale ed Endocrinochirurgia – Università degli Studi “La Sapienza”, Roma',
      'Abilitazione professore II fascia settore concorsuale 06/C1, chirurgia generale (MED18) ',
    ]"
    :pub="true"
    publication="È autore di oltre 70 pubblicazioni scientifiche su riviste nazionali ed internazionali, di capitoli su libri, di numerosi video scientifici e didattici"
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Elmore",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
